//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gql from 'graphql-tag'
import GlobalLogin from './components/GlobalLogin.vue'
import GlobalFooter from './components/GlobalFooter.vue'
const customerGql = gql`query customer($customerAccessToken:String!){
  customer(customerAccessToken:$customerAccessToken){
    firstName
    email
    orders(reverse:true,first:250){
      edges{
        node{
          fulfillmentStatus
          lineItems(first:1){
            edges{
              node{
                customAttributes{
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}` 
const customerAccessTokenRenewGgl = gql`mutation customerAccessTokenRenew($customerAccessToken:String!){
  customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    userErrors {
      field
      message
    }
  }
}`
const customerAccessTokenDeleteGql = gql`mutation customerAccessTokenDelete($customerAccessToken:String!){
  customerAccessTokenDelete(customerAccessToken:$customerAccessToken){
    userErrors{
      message
    }
  }
}`
const searchGql =  gql`query search($query: String!){
searchList:products(first:250,query:$query){
    edges{
      node{
        title
        handle
        priceRange{
          minVariantPrice{
            amount
            currencyCode
          }
        }
        images(first:1){
          edges{
            node{
              url
            }
          }
        }
      }
    }
  }
}`
export default {
  components: { GlobalLogin,GlobalFooter },
  data() {
    return {
      customer:null,
      value: null,
      searchList:[],
      viewShow: true,
      menuShow:false,
      spinning:false,
      isNone:false,
      // diag:false,
    }
  },
  created(){
    if(localStorage.getItem('TSimLang')){
      this.$i18n.locale = localStorage.getItem('TSimLang')
    }else{
      if ((navigator.language || navigator.browserLanguage).toLowerCase() === 'ja') this.$i18n.locale = 'jpn'
    }
    if(this.$cookie.get('TSimAccessToken')){
      this.$apollo
        .mutate({
            // 更新的语句
            mutation: customerAccessTokenRenewGgl,
            // 实参列表
            variables: {
              customerAccessToken: this.$cookie.get('TSimAccessToken')
            }
        }).then(response => {
            if(response.data.customerAccessTokenRenew.userErrors.length>0){
              this.$store.commit('Login', false)
              this.$cookie.remove('TSimAccessToken');
              response.data.customerAccessTokenRenew.userErrors.forEach(item => {
                this.$message.error(item.message)
              });
            }else{
              const token = response.data.customerAccessTokenRenew.customerAccessToken.accessToken
              const expiresAt = new Date(response.data.customerAccessTokenRenew.customerAccessToken.expiresAt)
              this.$cookie.set('TSimAccessToken', token, { expires: expiresAt });
              this.$store.commit('Login', true)
              console.log(expiresAt+'TokenRenew');
              this.getUserInfo()
            }
        }).catch(error => {
            this.$cookie.remove('TSimAccessToken');
            this.$store.commit('Login', false)
            this.$message.error(error.message)
        })
    }
  },
  // mounted () {
  //   this.diag = true
  // },
  watch:{
    '$i18n.locale'(){
      this.value = null
      this.searchList = []
    }
  },
  methods:{
    getUserInfo(){
      this.$apollo.addSmartQuery('customer', {
        query: customerGql, variables: {
          customerAccessToken: this.$cookie.get('TSimAccessToken'),
        }, result({ data, loading, networkStatus}) {
            this.$store.commit('Email', data.customer.email)
            this.$store.commit('Name', data.customer.firstName)
            if(this.$cookie.get('TSimAccessToken')&&this.$store.state.name&&this.$store.state.email) {
              this.$store.commit('Login', true)
            }
            if(data.customer.orders.edges){
              let UserDevices = []
              data.customer.orders.edges.forEach(item => {
                if (item.node.fulfillmentStatus === "FULFILLED"&&item.node.lineItems.edges[0].node.customAttributes.length>0) {
                  UserDevices.push(item.node.lineItems.edges[0].node.customAttributes[0].value)
                }
              });
              this.$store.commit('UserDevices', [...new Set(UserDevices)])
            }
        },// 错误处理
        error(error) {
            this.$message.error(error.message)
        }
      });
    },
    search(){
      this.viewShow = false
      if(this.value.length>=2){
        this.spinning = true
        this.searchList = []
        this.$apollo.addSmartQuery('searchList', {
            query: searchGql, variables: {
              query: `(title:*${this.value}*) OR (tag:${this.value}) AND (tag:${this.$i18n.locale})`,
            }, result({ data, loading, networkStatus}) {
                this.spinning = false
                // console.log({ data, loading, networkStatus })
                this.searchList = [...data.searchList.edges].sort((a,b)=> a.node.priceRange.minVariantPrice.amount - b.node.priceRange.minVariantPrice.amount)
                if (!this.searchList.length) this.isNone = true
            },// 错误处理
            error(error) {
                this.$message.error(error.message)
            }
        });
      }else{
        this.searchList = []
        this.isNone = false
      }
    },
    handleCancel(){
      this.$store.commit('LoginShow', false)
    },
    onLogout(){
      this.$dialog.confirm({
        title: this.$t('common.logout_text'),
        confirmButtonText:this.$t('common.ok'),
        cancelButtonText:this.$t('common.cancel'),
        }).then(() => {
          this.logout()
        }).catch(() => {
          // on cancel
        })
    },
    logout(){
      this.$apollo
        .mutate({
            // 更新的语句
            mutation: customerAccessTokenDeleteGql,
            // 实参列表
            variables: {
              customerAccessToken: this.$cookie.get('TSimAccessToken')
            }
        }).then(response => {
            if(response.data.customerAccessTokenDelete.userErrors.length>0){
              response.data.customerAccessTokenDelete.userErrors.forEach(item => {
                this.$message.error(item.message)
              });
            }else{
              this.$cookie.remove('TSimAccessToken');
              this.$store.commit('Login', false)
              this.$store.commit('Name', null)
              this.$store.commit('Email', null)
              this.$store.commit('UserDevices', [])
              if (this.$route.name==='Order') this.$router.push({ name: 'Home'});
            }
        }).catch(error => {
            this.$message.error(error.message)
        })
    },
    select(menu){
      if(menu.url==this.$route.name){
        this.viewShow = true 
        return
      }
      if(menu.url=='Logout'){
        this.$dialog.confirm({
        title: this.$t('common.logout_text'),
        confirmButtonText:this.$t('common.ok'),
        cancelButtonText:this.$t('common.cancel'),
        }).then(() => {
          this.logout()
        }).catch(() => {
          // on cancel
        });
        return
      }
      this.viewShow = true 
      this.$router.push({ name: menu.url});
    },
    setLang(lang){
      this.$i18n.locale = lang
      localStorage.setItem('TSimLang', lang)
    }
  },
  computed:{
    routerLink(){
      return [{ text: this.$t("common.product") ,url:'Home' },
              { text: this.$t("common.order") ,url:'Order' },
              { text: this.$t("common.enquiry") ,url:'Enquiry' },
              { text: this.$t("common.logout"),url:'Logout' }]
    },
    searchNone(){
      return { image:'error',des:this.$t('home.search_3') }
    },
    searchTips(){
      return { image:'search',des:this.$t('home.search_4') }
    }
  },
}
