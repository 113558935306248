export default {
  dataplanenquiry:{
    title:'Dataplan Enquiry',
    desc: "You can see your data usage directly from your phone settings. Alternatively, enter the order number from your voucher.",
    support:"Technical support",
    golive:"Customer Service is available from 08:00 - 22:30(UTC+8) (no voice call service).",
    message1:"* The eSIM QR code can only be installed once, if it has been installed successfully, the QR code will not be scanned again.",
    message2:"* QR code will no longer be displayed after the package expires.",
    contact:'WeChat Customer Service',
    iptplaceholder:'Please enter your order number',
    orderenquery:'Enquiry',
    type:'Type',
    activationtime:'Activation Time',
    expirationtime:'Expiration Time',
    terminatetime:'Terminate Time',
    statuinactive:'inactive',
    statususing:'Using',
    usage:'Usage (MB)',
    smdpaddress:'SM - DP + Address',
    activationcode:'Activation Code',
    copyalready:'Copy already',
    resetTime:'Daily plan reset time',
    installeSIM:'Install eSIM Now',
    clickInstallEsim:'Tab this button to install eSIM immediately',
    notImportQuery:'Not support inquiries for this plan, please contact TSim CS staff.'
},
  common:{
    message: "en",
    login:'Login',
    logout: 'Logout',
    register:'Register',
    forgot:'Forgot password',
    product: 'Top-up',
    order:'Top-up Orders',
    enquiry:'Dataplan Enquiry',
    cancel:'Cancel',
    ok:'Confirm',
    logout_text:'Confirm to logout?',
    previous:'Previous',
    next:'Next',
    video:'Click here to the video',
    loginTips:'Please login first',
    welcomeTo:'Welcome to TSim Tech!',
    welcomeBack:'Welcome back to TSim Tech!'
  },
  home:{
    search_1:"Search",
    search_2:'Search result',
    search_3:'Not found',
    search_4:'Please enter more than 2 words',
    from:'From',
    title:'All Dataplan',
    subtitle:'Please select the package you want to recharge',
    result:'None Dataplan',
    model_Dear:'Dear customers,',
    model_content1:'From October 4 to October 10, 2024, all products will receive a 10% discount at the checkout page.',
    // model_content2:'We would offer 10% discount to thank you for all your understanding during system maintenance. Thank you for your patience and understanding.',
    model_fotter1:'Japan and Global SIM',
    model_fotter2:'TSim Tech Co., Ltd',


  },
  modal:{
    email:'Enter email',
    password:'Enter password',
    firstName:'Enter first name',
    lastName:'Enter last name',
    signNow:'Sign up now',
    forgotTitle:"We'll send you an email to reset your password",
    forgotTips:'We have sent you an email with a link to update your password'

  },
  details:{
    pay:'Top-up',
    data:'Data：',
    days:'Day(s)：',
    card:'ICCID',
    verify_1:"Enter ICCID",
    verify_2:"Length should be 15 to 21",
    verify_3:"Please enter the correct ICCID",
    disabled:'Unavailable',
    instruction:'Instruction',
  },
  orders:{
    none:'No order yet',
    region:'Region',
    time:'Booking Time',
    payment:'Actual Payment',
    status:'Order Status',
    all:'All',
    nonePay:'Non-payment',
    fulfilled:'Fulfilled',
    unfulfilled:'Unfulfilled',
  },
  enquiry:{
    verify:'Enter ICCID',
    dataPlan:'Data plan',
    status:'Status',
    usagen:'Usage（MB）',
    actived:'Actived time',
    // expired:'Expired time',
    end:'End Time',
    booking:'Booking time',
    inactive:'Wait activate',
    using:'In-use',
    expired:'Expired',
    terminated:'Stopped',
  },
  footer:{
    release:'Release',
    release_text:'New European Plans Starting October 24, 2024.',
    viewmore:'Click to Learn More!',
    links:'Quick links',
    service:'Terms of Service（ terms and conditions ）',
    refund:'Refund Policy',
    privacy:'Privacy Policy',
    // privacy:'Privacy Policy',
    conditions:'Explanation of key terms and conditions',
    transactions:'Indication regarding the Act on Specified Commercial Transactions'
  },
  country:{
    asia:'Asia 8 Countries',
    australia:'Australia',
    Bali:'Bali',
    China:'China',
    Denmark:'Denmark',
    Dubai:'Dubai',
    europe:'Europe multiple destinations',
    guam:'Guam, Saipan, Tinian',
    hawaii:'Hawaii',
    hongkong:'Hong  Kong,  Macau',
    iceland:'Iceland',
    india:'India',
    indonesia:'Indonesia',
    Israel:'Israel',
    japan:'Japan',
    korea:'Korea',
    macau:'Macau',
    mainland:'Mainland China, Hong Kong,  Macau',
    malaysia:'Malaysia',
    newzealand:'New Zealand',
    philippines:'Philippines',
    saudi:'Saudi Arabia',
    singapore:'Singapore',
    switzerland:'Switzerland',
    taiwan:'Taiwan',
    thailand:'Thailand',
    turkey:'Turkey',
    united:'United Arab Emirates (UAE)',
    usa:'USA',
    USAcanada:'USA, Canada',
    vietnam:'Vietnam',
    Italy:'Italy',
    unitedkingdom:'United Kingdom',
    France:'France',
    Germany:'Germany',
    Spain:'Spain',
    Portugal:'Portugal',

  },
  newplan:{
    destination:'Destination',
    day:'Day(s)',
    type:'Type',
    options:'Options',
    jpprice:'JPY price',
    dailyplan:'Daily Plan',
    volumeplan:'Volume Plan',
    currentproducts:'Current Products'
  },
  apology:{
    text_1:'Thank you for purchasing a Japan&Global SIM Card.',
    text_2:'Due to unstable servers of our carrier, we are unable to charge this SIM card at this time.',
    text_3:'We apologize for the inconvenience, but please check the following information.',
    text_4:'If your cell phone is compatible with the e-SIM service, please contact us at',
    text_5:'If you click the above link and purchase the e-SIM service, you will receive a discount of JPY1,000 (~HKD60).',
    text_6:'Please enter',
    text_7:'in the coupon code box.',
    text_8:'',
    text_9:'We accept bank transfers from Japan/Korea/Hong Kong/China/Taiwan.',
    text_10:'Name: ',
    text_11:'Bank Name: ',
    text_12:'Branch: ',
    text_13:'Ordinary / Current: ',
    text_14:'Account No: ',
    text_15:'We will refund your money within one week. We apologize for any inconvenience caused.',
    text_16:'※Customers from Korea/Hong Kong/China/Taiwan',
    text_17:'If you would like to use other electronic payment methods such as Alipay/WeChat/Payme/Linepay, please contact us.',
    text_18:'I would like to receive an electronic payment refund.',
    text_19:'Japanese support(Japanese only):',
    text_20:'WhatsApp (English/Chinese):',
    text_21:'Line(English/Chinese):',
    text_22:'Email (English/Chinese):',
    text_23:'If you would like to order a new item, please let us know where to send it.',
    text_24:'Please let us know where you would like us to send the product.',
    text_25:'Shipping address',
    text_26:'Address: (Postal Code)',
    text_27:'Address: Name',
    text_28:'Your Name: E-mail',
    text_29:'Mail: Mailing address',
    text_30:'We will send it by "Express Mail" as soon as we confirm the contents of the e-mail.',
    text_31:'We apologize for any inconvenience this may cause and thank you in advance for your cooperation.',
  },
  tsim:{
    service:`
    <h1>Terms of Service（ terms and conditions ）</h1>
    <h3>Global SIM Terms of Use</h3>
    <p>(hereinafter referred to as "3rdcompass co,ltd") sets out the Global SIM (hereinafter referred to as the "Service") Terms of Use (hereinafter referred to as the "Terms") as follows, pursuant to which the Service is provided.</p>
    <p>The Service is a prepaid wireless data communication service developed by TSIM TECH CO. TSIM TECH handles the wireless data communication service, the billing opening work and the selection of overseas roaming operators, while the Company handles the sales and agency services in Japan.</p>
    <h3>Article 1 (Definitions).</h3>
    <p>The terms used in these Terms and Conditions are defined as follows</p>
    <p>The term 'the Service' refers to the prepaid wireless data communication service provided by the Company.</p>
    <p>The term "SIM Card" means the IC card purchased in accordance with these Terms and Conditions that records the Subscriber's information, and the SIM Card shall include three SIM card types: standard SIM card, microSIM card and nanoSIM card.</p>
    <p>'Subscriber' means an individual who uses the Service.</p>
    <p>'Purchaser' means the individual who has purchased the SIM Card in order to use the Services.</p>
    <p>'Mobile operator' refers to a mobile operator that has concluded an interconnection agreement or other contract with TSIM TECH for the provision of wireless data communications.</p>
    <p>'Wireless data communication' means wireless data communication provided by a mobile operator for the transmission of codes by means of packet switching.</p>
    <p>'Add-on function services' means add-on function services as defined by the Company.</p>
    <p>'Subscriber Line' means the telecommunications line used by the Subscriber in accordance with the contract for the Service.</p>
    <p>Terminal Equipment" means terminal equipment of the type specified in the Regulations Concerning Technical Standards Compliance Approval of Terminal Equipment, etc. (Ministry of Internal Affairs and Communications Ordinance No. 15 of 2004).</p>
    <p>'Agreement carrier' means a telecommunications carrier that has entered into an interconnection agreement or other contract with the Company.</p>
    <h3>Article 2. (These Terms and Conditions)</h3>
    <p>The Subscriber shall use the Service in accordance with these Terms and Conditions and other regulations relating to the Service.</p>
    <p>The Company reserves the right to change the Terms and Conditions. In this case, the terms and conditions of use of the Services shall be governed by the amended Terms and Conditions.</p>
    <p>If the Terms are changed, the Company shall make a public announcement on the Company's website or on the Services, and the changed Terms shall come into effect from the revision date specified at the time of the announcement.</p>
    <p>The Company reserves the right to change or terminate the Service and the content of the Service without notice.</p>
    <p>The Terms and Conditions are written in Japanese and if there are any differences between the translated version of the Terms and Conditions and the Japanese version, the content of the Japanese version shall prevail.</p>
    <h3>Article 3 (Policy on handling of personal information)</h3>
    <p>The Company shall use the personal information obtained from the Subscriber within the scope of the purposes of use described in the Company's "Handling of personal information", and the Subscriber shall apply for the Service with consent.</p>
    <h3>Article 4 (Application and availability period for the Service and Additional Function Services)</h3>
    <p>The contract for the use of the Services shall come into existence when the Purchaser has agreed to these Terms and Conditions and has applied for the Services in the manner specified by the Company, and when the Company has registered such Purchaser as a Subscriber. However, if the SIM Card purchased by the Purchaser has an activation set-up date, the Purchaser shall apply for the Service in the manner designated by the Company by that set-up deadline date.</p>
    <p>If the SIM Card purchased by the Purchaser has an activation set-up date deadline, the Company shall deem that the Purchaser has abandoned the Service if the Purchaser does not activate the SIM Card by that set-up deadline date.  If the SIM Card has an expiry date, the Purchaser shall be deemed to have abandoned the Service if the Purchaser does not activate it by the expiry date. When the Purchaser abandons the Service, the Company shall not refund the purchase price of the SIM Card to the Purchaser.</p>
    <p>If the Subscriber wishes to increase the data capacity of the Subscriber's Subscriber Line or to change the usage period and select the country of use for a product that allows selection of the country of use, the data capacity of the Subscriber Line will be increased and the extension of the period of availability will be renewed upon application using the method specified by the Company and acceptance of the application by the Company. TSIM TECH, our business partner, is responsible for all acceptances.</p>
    <h3>Article 5 (Acceptance of application for use of the Services)</h3>
    <p>If the Company confirms that the Purchaser falls into any of the following categories in relation to the application set out in Article 4 (Application for the Services and Additional Functional Services and Usage Period), the Company may not accept the application and the applicant for the Services shall acknowledge this in advance.</p>
    <p>If there are any false statements, errors, omissions or omissions in the application for use.</p>
    <p>If you have had your entitlement to use this service or any of our other services suspended or revoked in the past.</p>
    <p>If the Company deems that there are any other obstacles to the performance of its business or technical work.</p>
    <h3>Article 6 (Use of the Services).</h3>
    <p>The Subscriber shall take full responsibility for the information transmitted through the Service and the use of the Service, and shall not cause any inconvenience or damage to other Subscribers, third parties or the Company, except as expressly provided for in these Terms and Conditions.</p>
    <p>If the Subscriber causes damage to another Subscriber, a third party or the Company, or if a dispute arises between the Subscriber and another Subscriber or a third party in relation to the use of the Service, the Subscriber shall compensate for such damage or resolve such dispute at his/her own expense and responsibility and shall not cause any inconvenience to the Company. and shall not cause any damage.</p>
    <h3>Article 7 (Communication area)</h3>
    <p>The communication area of the Service shall be as per the communication area of the mobile operator. The Service may only be performed when the connected terminal equipment is in the communication zone. However, even within such communication area, communication may not be possible indoors, in underground parking lots, behind buildings, in tunnels, in mountainous areas and other places where radio waves are difficult to transmit.</p>
    <p>In the case of the preceding paragraph, the Subscriber may not claim any compensation for damages due to the unavailability of the Service from the Company, except in cases where the unavailability of the Service is caused by the Company's intentional or gross negligence.</p>
    <h3>Article 8 (Restriction of use).</h3>
    <p>The Company may temporarily suspend communications if there are unavoidable technical, maintenance or other business reasons for the Company, or if there are restrictions on the use of communications by mobile phone operators under the provisions of the terms and conditions of the telecommunications services provided by the mobile phone operators or under the provisions of any contract concluded between the mobile phone operators and the Company. The service may be temporarily restricted.</p>
    <p>The Subscriber may not claim compensation from the Company for any damage caused by the restriction of communications, except in cases where the restriction is caused by the Company's intent or gross negligence.</p>
    <h3>Article 9 (Time and other restrictions)</h3>
    <p>In addition to the cases under the preceding article, the Company may restrict the transmission times or the availability of communications in certain areas when communications are extremely busy and congested.</p>
    <p>In the case of the preceding paragraph, priority will be given to communications that contain matters necessary for disaster prevention or relief, ensuring transport, communication or power supply or maintaining order in the event of a natural disaster, incident or other emergency, and communications that contain urgent matters for the public interest. In order to ensure that communications are handled in a timely manner, measures to suspend the use of communications by means other than mobile radio equipment used by bodies designated by the Minister of Internal Affairs and Communications in a public notice in accordance with the provisions of the Ordinance for Enforcement of the Telecommunications Business Law (limited to mobile radio equipment that is specified by the Company or the mobile operator in consultation with those bodies) (i.e., to subscriber lines in specific areas, etc.) are taken. (including measures to suspend communications).</p>
    <p>The Company may restrict or disconnect the communication if the communication time in a certain period exceeds the time specified by the Company or if the communication capacity in a certain period exceeds the capacity specified by the Company.</p>
    <p>The Company may limit the speed or volume of communications made using communication procedures that continuously and heavily occupy bandwidth in order to ensure fairness of use among Subscribers and to provide the Service smoothly.</p>
    <p>In the case of the preceding four paragraphs, the Subscriber may not claim any compensation for damages due to the limitation of communication time, etc. from the Company.</p>
    <p>The Company may collect, analyse and accumulate information on communications for the purpose of limiting the communication time, etc. as stipulated in this Article.</p>
    <h3>Article 10 (Measurement of communication capacity)</h3>
    <p>The method of measuring the communication capacity for the Service shall be as follows.</p>
    <p>The communication capacity shall be calculated from the time when the contracted lines, etc. of both the sender and the callee are connected and ready for communication (when the communication is a manual connection communication and the other party is specified, the time when the other party can be communicated with), and shall be calculated from the time when the caller or callee terminates the communication. The capacity shall be measured by the Company's equipment (including the equipment of the agreed operators) up to the time when the signal is received and the communication is disabled.</p>
    <p>Notwithstanding the provisions of the preceding item, when communications are temporarily restricted for reasons not attributable to the sender or receiver of the communications, such as a breakdown of the contract line (when communications are temporarily restricted in accordance with Article 8 (Restriction of Communications Use), this shall be the time when the restriction is notified), the capacity shall be measured according to the provisions separately determined by the contracting operator. The capacity shall be the communication capacity.</p>
    <h3>Article 11 (Communication speed, etc.)</h3>
    <p>The Subscriber acknowledges that the communication speed specified by the Company on the Service does not indicate the upper limit of the actual communication speed and that the communication speed will change and decrease due to connection conditions, the SIM Card used by the Subscriber, information and communication equipment, network environment and other reasons.</p>
    <p>The communication speed is provided on a best-effort basis and the actual communication speed will vary depending on the communication environment and network congestion.</p>
    <p>The Company shall not guarantee any communication speed in the Service.</p>
    <p>The Subscriber agrees in advance that messages, data, information, etc. sent or received using the Service may be damaged or lost due to radio wave conditions, etc.</p>
    <h3>Article 12 (The SIM Card)</h3>
    <p>The SIM Card is required to use the Service.</p>
    <p>The SIM Card is available in several different sizes, and the Subscriber is responsible for selecting the size of the SIM Card that is compatible with the terminal equipment when making such purchase.</p>
    <p>The Subscriber shall manage the SIM Card with the care of a good manager.</p>
    <p>The Subscriber shall not allow any third party other than the Subscriber to use, lend, transfer, buy or sell the SIM Card.</p>
    <p>The Subscriber shall bear any damage caused by inadequate management of the SIM Card by the Subscriber, errors in use, use by a third party, etc., for which the Company shall not be liable. Any charges, etc. incurred due to the use of the SIM Card by a third party shall be borne by the Subscriber, who is responsible for the management of the SIM Card concerned.</p>
    <p>Only if the SIM Card malfunctions for reasons not attributable to the Subscriber, the Company shall repair or replace the SIM Card at the Company's expense (replacement of a SIM Card of a different type shall not be possible). The same shall apply hereinafter) at our expense.</p>
    <p>The Subscriber shall not read, change or delete the Subscriber Identification Number (ICCID) or other information registered on the SIM Card.</p>
    <p>The Subscriber shall not make any changes or damage to the SIM Card that would interfere with the business of the Company, the mobile operator or any third party. If the SIM Card malfunctions for reasons attributable to the Subscriber, the Subscriber shall bear the costs of repair or replacement. In this case, the Subscriber shall pay the Company the SIM Card Damage Fee determined by the Company in addition to the costs of repair or replacement.</p>
    <p>The Subscriber agrees in advance that the SIM Card cannot be returned or replaced, except as provided for in the preceding paragraph.</p>
    <h3>Article 13 (Equipment)</h3>
    <p>The Subscriber shall prepare and maintain the equipment required to use the Services at its own cost and responsibility.</p>
    <p>The Company shall not be liable for any damage caused to the Subscriber or third parties in the case of the preceding paragraph.</p>
    <h3>Article 14 (Interruption of provision)</h3>
    <p>The Company may interrupt the provision of the Services in any of the following cases</p>
    <p>When it is unavoidable due to maintenance or construction of the Company's telecommunications equipment.</p>
    <p>When the use of communications is restricted in accordance with Article 8 (Restriction of Communications Use) or Article 9 (Restriction of Communication Time, etc.).</p>
    <p>When the use of telecommunications is restricted in accordance with the terms and conditions of mobile phone operators.</p>
    <p>The Company will not compensate for damages or refund all or part of the Service Charges in relation to any interruption of use in accordance with this Article.</p>
    <h3>Article 15 (Suspension of use)</h3>
    <p>The Company may suspend the provision of the Service for a period determined by the Company if the Subscriber falls into any of the following categories, in addition to the cases specified as specifications for the Service.</p>
    <p>When it is found that the contents of the application concerning the Service are contrary to the facts.</p>
    <p>If the Subscriber fails to notify the Company of any change in the information that has been submitted to the Company, or if the submitted information is found to be contrary to the facts.</p>
    <p>When the Subscriber does not respond to the confirmation of the Subscriber as stipulated in Article 34 (Subscriber confirmation).</p>
    <p>If the Subscriber commits any of the prohibited acts set out in Article 31 (Prohibited acts).</p>
    <p>When the SIM Card is used with equipment that does not conform to technical standards, in breach of the provisions of Article 13 (Equipment).</p>
    <p>When any act is committed that interferes or is likely to interfere with the Company's business or the telecommunications equipment used for the Service.</p>
    <p>When the Service is used in a manner that causes serious hindrance to other subscribers.</p>
    <p>When the Service is used in an illegal manner.</p>
    <p>When the Subscriber dies or is liquidated.</p>
    <p>In addition to the preceding items, when an act in breach of the provisions of these Terms and Conditions has been committed.</p>
    <p>The Company will not compensate for damages or refund all or part of the Service fee for suspension of the provision of the Service in accordance with this Article.</p>
    <h3>Article 16 (Cancellation of subscription by the Company)</h3>
    <p>The Company may terminate the Subscriber's subscription agreement if the Subscriber whose provision of the Services has been suspended pursuant to paragraph 1 of the preceding article still fails to resolve the fact.</p>
    <p>If the Subscriber falls under any of the provisions of paragraph 1 of the preceding Article and such fact is found to be a significant hindrance to the performance of the Company's business, the Company may, notwithstanding the provisions of the preceding paragraph, terminate the Subscriber's subscription contract without suspending the use.</p>
    <h3>Article 17. (Due date).</h3>
    <p>If the provision of the Services is suspended or the contract for the use of the Services is terminated in accordance with the provisions of the preceding two Articles, the relevant Subscriber shall lose the benefit of the term and shall be liable for all debts to the Company in relation to the Services incurred up to the date of suspension of the provision of such Services or termination of the contract for the use of the Services as instructed by the Company. The payment shall be made in a lump sum in the manner specified.</p>
    <h3>Article 18 (Cancellation).</h3>
    <p>The Subscriber agrees in advance that the Service Agreement cannot be terminated during the validity period of the Service.</p>
    <p>If the SIM Card is repaired or replaced and the Subscriber does not receive the repaired or replaced SIM Card, the Service shall be terminated on a date separately specified by the Company.</p>
    <h3>Article 19 (Fees)</h3>
    <p>The charges for the Service provided by the Company shall be as set out in a separate tariff set out by the Company and the Subscriber shall be obliged to pay for these charges.</p>
    <p>The charges for the Service shall be paid separately from the SIM Card in the manner chosen by the Purchaser, including recharge and recharge charges according to the details chosen by the Purchaser. TSIM TECH shall be responsible for collecting such charges and shall use them to pay for the services in accordance with the Purchaser's choice.</p>
    <h3>Article 20 (Calculation of charges, etc.)</h3>
    <p>The method of calculation of the charges and the method of payment of the charges shall be determined separately by the Company. The information shall be published in a place designated by the Company.</p>
    <h3>Article 21 (Surcharge)</h3>
    <p>If the Subscriber is unlawfully exempted from paying the charges, the Subscriber shall be liable for an amount equal to twice the amount so exempted (not including the amount equivalent to consumption tax) plus an amount equivalent to consumption tax (in the case of charges to which an amount equivalent to consumption tax is not to be added in accordance with the tariff, the amount equivalent to consumption tax shall be added), in addition to the amount so exempted. (an amount equal to twice the exempted amount) as a surcharge.</p>
    <h3>Article 22 (Interest on arrears)</h3>
    <p>If the Subscriber has not paid the charges and other obligations (excluding overdue interest) after the due date, the Subscriber shall pay as overdue interest the amount calculated at a rate of 14.5% per annum for the number of days from the day after the due date to the day before the date of payment.</p>
    <p>However, this shall not apply if payment is made within 15 days from the day after the due date.</p>
    <h3>Article 23 (Changes to charges etc.)</h3>
    <p>The Company may change the fees for the Service and the method of payment thereof by giving prior notice to the Subscriber in a manner the Company considers appropriate. However, the Company may replace notification to the Subscriber by posting details of changes to the fees for the Service and the method of payment thereof on the Company's website or in a place that the Company deems appropriate.</p>
    <h3>Article 24 (Limitation of the scope of compensation to be made by the Company)</h3>
    <p>If the Company causes damage to the Subscriber, etc. for reasons attributable to the Company, the Company shall be liable for direct and ordinary damage to the Subscriber, etc. within the scope of the Service Charges, and the Company shall not be liable for special damage such as lost earnings, regardless of whether such damage was foreseeable.</p>
    <h3>Article 25 (Exemption from liability).</h3>
    <p>In the repair or restoration of telecommunications equipment, the content of messages, data, information, etc. stored in the telecommunications equipment may change or disappear. The Company shall not be liable to compensate for any damage caused by this, unless the damage was caused by the Company's intent or gross negligence.</p>
    <p>The Company shall not bear any costs for modification or alteration of the equipment (hereinafter in this article referred to as 'modification etc.') even if such modification or alteration is required as a result of changes to these Terms and Conditions, etc.</p>
    <h3>Article 26 (Maintenance responsibility of the Company)</h3>
    <p>The Company shall endeavour to ensure that its telecommunications equipment complies with the Business Telecommunications Equipment Regulations (Ministry of Posts and Telecommunications Ordinance No. 30 of 1985).</p>
    <h3>Article 27 (Maintenance responsibility of the Subscriber)</h3>
    <p>The Subscriber shall maintain its own terminal equipment in conformity with the technical standards and technical conditions specified by the Company.</p>
    <p>In addition to the provisions of the preceding paragraph, the Subscriber shall maintain the equipment (limited to mobile radio equipment) to comply with the radio equipment regulations.</p>
    <h3>Article 28 (Repair or restoration)</h3>
    <p>The Company will promptly repair or restore telecommunications equipment installed by the Company if it breaks down or is lost. However, repair or restoration is not guaranteed.</p>
    <h3>Article 29 (Limitation of guarantees)</h3>
    <p>The Company cannot guarantee the quality of communications over any telecommunications equipment connected via an interconnection point or otherwise, except for the Company's telecommunications equipment.</p>
    <p>Due to the high level of complexity of the Internet and computer technology and of the network itself, we cannot guarantee that the Service will be free from defects or malfunctions based on current general technical standards.</p>
    <h3>Article 30 (Support)</h3>
    <p>The Company shall provide the Subscriber with technical support for the use of the Services, the content of which shall be determined by the Company.</p>
    <p>The Company is not obliged to provide any technical services to the Subscriber, whether maintenance, debugging, updating or upgrading, except as provided in the preceding paragraph.</p>
    <h3>Article 31 (Prohibitions).</h3>
    <p>The Subscriber shall not perform any of the following acts when using the Services.</p>
    <p>Acts that infringe the copyrights or other intellectual property rights of other Subscribers, third parties or the Company.</p>
    <p>Acts that infringe the property, privacy or portrait rights of other subscribers or third parties.</p>
    <p>Acts that discriminate against or slander other Subscribers or third parties, or that damage their honour or credit.</p>
    <p>Acts that lead to fraud or other crimes.</p>
    <p>Acts of transmitting or posting images or documents that are obscene, child pornography or child abuse.</p>
    <p>Establishing or soliciting the establishment of pyramid schemes.</p>
    <p>Acts of transmitting or posting information that is not true, or acts of falsifying or deleting information.</p>
    <p>Actions for the purpose of profit-making through or in relation to the Services, or actions for the purpose of preparing for such profit-making, which have not been approved in advance by the Company.</p>
    <p>Acts that interfere with the operation of the Service or servers or other equipment managed by third parties.</p>
    <p>Acts of sending advertising or solicitation emails to other subscribers or third parties without permission, acts of obstructing the sending or receiving of emails by other subscribers or third parties by sending large volumes of emails, or acts of sending emails (harassing emails) that the recipient is or may be disgusted by.</p>
    <p>Acts that use or provide harmful programmes such as computer viruses, or acts that support, advertise or recommend them.</p>
    <p>Acts of using the Service by impersonating another subscriber.</p>
    <p>Acts of obtaining another person's ID or other information by means of a website or e-mail, etc., against the intention of the person to whom the information belongs, through error, etc.</p>
    <p>Acts that violate laws and regulations or public order and morals (prostitution, violence, cruelty, etc.) or that cause disadvantage to other subscribers or third parties.</p>
    <p>Acts such as transferring, lending, reselling, changing the name of, pledging or otherwise offering this service as security.</p>
    <p>Acts that encourage the acts set out in the preceding items.</p>
    <p>Actions that the Company deems may fall under any of the preceding items.</p>
    <p>Any other acts that the Company deems inappropriate.</p>
    <h3>Article 32 (Sending of location data)</h3>
    <p>Location information (meaning information relating to the location of the mobile radio equipment connected to the subscriber's line) shall be provided from the telecommunications equipment pertaining to the Company during communications between the connection point established by the mobile telephone operator between the Company and TSIM TECH for wireless data communications and the subscriber's line by a method separately specified by the mobile telephone operator.  When a request for location information (hereinafter the same shall apply in this Article) is made, the Subscriber shall consent in advance to the sending of location information to the connection point, provided that the Subscriber has previously made settings pertaining to the sending of location information to the Company.</p>
    <p>The Company shall not be liable for any damage resulting from the location information sent in accordance with the preceding paragraph, irrespective of the cause.</p>
    <h3>Article 33 (Collection of information)</h3>
    <p>The Company may collect and use information necessary to provide technical support, etc. to the Subscriber in relation to the Service. The Subscriber acknowledges in advance that the Company may not be able to provide sufficient technical support, etc. due to the Subscriber not providing the necessary information.</p>
    <h3>Article 34 (Subscriber identification)</h3>
    <p>When purchasing the SIM Card, the Purchaser may be required to present identification documents as required by the Company.</p>
    <p>If the service falls under the services stipulated in the Mobile Phone Abuse Prevention Act, the Purchaser shall present the official identification document of the Subscriber as stipulated in the same Act.</p>
    <h3>Article 35 (Discontinuation of the Service).</h3>
    <p>The Company may change, add and discontinue the Services in whole or in part.</p>
    <p>When the Company discontinues the Service in accordance with the preceding paragraph, it will notify the Subscriber a reasonable period of time in advance.</p>
    <h3>Article 36 (Changes to technical specifications, etc., of the Service)</h3>
    <p>The Company shall not bear the costs of modification or removal of the SIM Card used by the Subscriber, even if such modification or removal is required due to changes in the technical specifications or other terms and conditions of provision of the Service or due to renewal of telecommunications equipment.</p>
    <h3>Article 37 (Prohibition of transfer).</h3>
    <p>The Subscriber may not transfer the Subscriber's position as Subscriber, as well as the rights and obligations of the Subscriber under these Terms and Conditions, to a third party without the prior consent of the Company.</p>
    <h3>Article 38 (Severability).</h3>
    <p>If any part of these Terms and Conditions is found to be invalid and unenforceable, the validity of the remaining parts of these Terms and Conditions shall remain unaffected thereby and shall continue to be valid and enforceable according to their terms.</p>
    <h3>Article 39 (Consultation)</h3>
    <p>If the Company and the Subscriber have any doubts about the Services or these Terms and Conditions, they shall resolve them in good faith through consultation.</p>
    <h3>Article 40 (Agreed Jurisdiction)</h3>
    <p>If the need for litigation arises between the Subscriber and the Company in relation to these Terms and Conditions, the Tokyo District Court or the Tokyo Summary Court shall be the court of exclusive jurisdiction of the first instance.</p>
    <h3>Article 41 (Governing law).</h3>
    <p>The formation, validity, performance and interpretation of these Terms and Conditions shall be governed by the laws of Japan.</p>
    <p class="text-end">1 May 2022 Establishment.</p>
    <h3>◆Japanese domestic agency:</h3>
    <p>3rdcompass Inc.</p>
    <p>2F Shibuya 3-chome Square Bldg, 3-5-16 Shibuya, Shibuya-ku, Tokyo 150-0002, Japan</p>
    `,
    refund:`
    <h1>Refund policy</h1>
    <h3>◆Refund and return policy</h3>
    <p>We do not accept refunds or returns. If the product is defective or damaged, please check the following information and contact the store of purchase (or distributor) for replacement of the product.　If the product is defective or damaged, please check the following items and contact the store of purchase (or distributor) for replacement.</p>
    <p>1) Receipt exists.</p>
    <p>2) The SIM card has not been removed from the protection card (communication not running).</p>
    <p>3) The ICCID is properly known (The status of communication use can be checked).</p>
    <h3>◆Connection instructions</h3>
    <p>Enquiry centre:</p>
    <p>≪Japan only≫ ※Refers to the use of telecommunications within Japan.</p>
    <p>Agency: In-Plus Call Center (365 days a year, 9:00-18:00 〈Japan/English/Chinese/Korean〉)</p>
    <p> contact information ：<a href="https://www.in-plus.co.jp/globalsim-contact/">https://www.in-plus.co.jp/globalsim-contact/</a></p>
    <p>＊We will reply to you within 24 hours after you fill out the inquiry form. Thank you for your patience.</p>
    <p>＊By the time you hear from us</p>
    <p><a href="https://www.youtube.com/watch?v=e4kawn6hDa8&feature=youtu.be">・ https://www.youtube.com/watch?v=e4kawn6hDa8&feature=youtu.be</a></p>
    <p><a href="https://www.youtube.com/watch?v=iowS1LExtC4">・https://www.youtube.com/watch?v=iowS1LExtC4</a></p>
    <p>Thank you very much for your understanding.</p>
    <p>《outside Japan》</p>
    <p>TSIM TECH</p>
    <p>WhatsApp/Telegram: +852 6014 0451</p>
    <p>Line: @871lfazn</p>
    <p>Email: adminhk@tsimtech.com English, Chinese</p>
    <p>Regarding the fact that the connection was established but communication is not possible</p>
    <p>Please contact TSIM TECH for information on the communication environment.</p>
    <p>1.Contact Whatsapp. Contact  +852 6014 0451 immediately</p>
    <p>All SIM cards and network services are provided by major telecom operators.</p>
    <p>TSIM TECH will investigate the cause. (In doing so, please inform us of your mobile phone settings, if necessary).</p>
    <p>If the problem is not resolved, TSIM TECH will contact you with refund details.</p>
    <p>→However, no refund will be made if the problem is with the SIM card and model connection and not with the network of the operator.</p>
    <p>→The decision in this respect will be made after consultation between TSIM TECH and the purchaser.</p>
    `,
    privacy:`
    <h1>Privacy Policy</h1>
    <p>This service is a prepaid wireless data communication service developed by TSIM TECH CO.,LIMITED (hereinafter referred to as " TSIM TECH ") and sold in Japan through a business alliance with us (hereinafter referred to as "the Service"). Triproaming handles the wireless data communication service, the billing opening process and the selection of overseas roaming operators, while the Company provides sales and agency services in Japan.As the management and operation of this service in Japan is performed by 3rdcompass Inc (hereinafter referred to as 'the Company'), the Company's approach to the protection of personal information</p>
    <p>(privacy policy) in this service is described below.</p>
    <p>The Company considers the protection of the personal information of its customers and employees to be a social responsibility of the Company and, 
    in accordance with the Act on the Protection of Personal Information (hereinafter referred to as the "Personal Information Protection Act") and other laws and regulations, 
    the Company has established and operates a personal information protection management system.
    In addition to establishing and operating a personal information protection management system, we are constantly aware of the latest trends in IT technology, 
    changes in social demands and changes in the economic environment in order to realise continuous improvement of the system.
    We are constantly aware of the latest trends in IT technology, changes in social demands and fluctuations in the economic environment in order to achieve continuous improvement of the system.</p>
    <p>Personal information relating to this service is stored by TSIM TECH and is not held by the Company.</p>
    <p>The Company will provide TSIM TECH with operational guidance and advice in accordance with Japanese law.</p>
    <h3>◆Collection of personal information</h3>
    <p>The Company acquires personal information (including information relating to individuals) mainly by the following methods and uses and stores this as personal data.</p>
    <p>・Directly from the person.</p>
    <p>・Methods of acquisition from third parties.</p>
    <p>・Methods of acquisition based on the use of our services, etc.</p>
    <p>・Methods of acquisition by cookies, web beacons (including similar technologies), etc.</p>
    <p>・Methods of acquisition by recording calls, photographs, videos, etc.</p>
    <p>・Methods of acquiring information based on other matters relating to the Company.</p>
    <h3>◆How personal information is used</h3>
    <p>Provision of products and services and after-sales service in the Company.</p>
    <p>・Use of personal data for purposes that are clearly recognisable in view of the purpose for which the information was obtained.</p>
    <p>・Billing and collection of various fees and debt protection.</p>
    <p>・Information required for advertising, promotion, sales and solicitation.</p>
    <p>・Sweepstakes and campaigns</p>
    <p>・Corporate social responsibility (CSR) activities</p>
    <p>・Management of contractual relationships with business partners</p>
    <p>・Employment (including recruitment) of employees, etc. Employment (including recruitment) and personnel management of employees, etc.</p>
    <p>To the extent necessary for the performance of other tasks related to the preceding items.</p>
    <h3>◆Non-disclosure of personal information</h3>
    <p>The Company will not disclose your personal data to third parties, except as provided for in the preceding paragraph or as required by law. However, in the course of carrying out our business, we may outsource to our business partners in line with the stated criteria.</p>
    <h3>◆Management of personal data</h3>
    <p>The Company endeavours to understand external factors in the acquisition, use, storage, provision, deletion and disposal of personal data, and to comply with the Act on the Protection of Personal Data, guidelines in the economic and industrial field regarding the Act, and other relevant laws and regulations, as well as to fulfil these requirements. We have established rules and regulations concerning their duties, etc., and ensure that our employees comply with them, as well as organisational, personnel, physical and technical safety control measures.</p>
    <h3>◆Complaints and consultations about personal information</h3>
    <p>Complaints and consultations regarding the handling of personal information, as well as requests for reference or correction of personal information, should be directed to our contact point for personal information, and will be dealt with as soon as reasonably possible.</p>
    <h3>◆Outsourcing of personal information</h3>
    <p>The Company may outsource all or part of the handling of personal information or provide personal information to parties other than the Company within the scope of how the personal information is used. In this case, the Company will select an appropriate party, conclude an appropriate contract to ensure that the personal data concerned is handled appropriately by the party to which it is entrusted, and ascertain the status of the handling of personal data by the party to which it is entrusted in a timely manner.</p>
    <h3>◆Procedure for requesting disclosure</h3>
    <p>The Company will respond appropriately and promptly to requests for disclosure and other procedures as required by law. If you wish to request disclosure of your information, we will respond to your request within a reasonable period of time and to a reasonable extent, after verifying that the person making the request is the person in question. However, there may be cases where the request for disclosure cannot be complied with in accordance with the law.</p>
    <h3>◆Correction, deletion, suspension and erasure of personal data</h3>
    <p>If a person wishes to correct, add to or delete his or her personal data held by the Company, the Company will, after verifying the identity of the person making the request, correct, add to or delete the information within a reasonable period of time and to a reasonable extent if the information is untrue.</p>
    <p>If you wish to stop the use or erase your personal data in our possession and one of the following items applies, we will, in principle, stop the use or erase your personal data to the extent necessary to prevent infringement of your rights and interests, after confirming the identity of the person making such a request. The company will do so. However, in cases where the request for cessation of use or erasure cannot be complied with in accordance with the law, or alternative measures permitted by law may be implemented in lieu of cessation of use or erasure. Please note that if all or part of the personal data is ceased to be used or erased, it may not be possible to provide services in accordance with your request, although we regret this.</p>
    <h3>◆Changes to privacy policy</h3>
    <p>・The content of this Privacy Policy may be changed without notice to the user.</p>
    <p>・Unless otherwise specified by the Company, the amended Privacy Policy shall take effect from the time it is posted on the Website.</p>
    <h3>◆Method and contact point for receiving disclosure, etc.</h3>
    <p>Enquiries relating to the handling of personal data should be directed to the following contact point.</p>
    <p>≪Contact details.≫ ※About this service</p>
    <p>Address 〒150-0002 2F Square Bldg, 3-5-16, Shibuya, Shibuya-ku, Tokyo</p>
    <p>3rdcompass Inc.　Personal information contact.</p>
    <p>Mail：privacy★3compass.net   （Please change ★ to @ and reply）</p>
    <p>※We will try to reply within a maximum of 10 working days.</p>
    <h3>※Personal data for this service</h3>
    <p>Address: Unit 1101-1102, Times Tower, 928 Sheung Sha Wan Road, Lai Chi Kok, Kowloon, Hong Kong</p>
    <p>TSIM TECH CO.,LIMITED</p>
    <p>WhatsApp/Telegram :+852 6014 0451</p>
    <p>LINE :@871lfazn</p>
    <p>Mail: adminhk★tsimtech.com（Please change ★ to @ and reply）</p>
    <p> ※We will try to reply within a maximum of 10 working days.</p>
    `,
    conditions:`
    <h1>Explanation of key terms and conditions</h1>
    <p>Global SIM" (the "Service"). Global SIM (hereinafter referred to as the "Service") is a communication line using international wireless roaming service, which allows users to use packet communications such as web access on 4G compatible iPhones, smartphones, iPads or tablets at a fixed rate, limited to a set data volume or usage period depending on the amount paid in advance. Service.</p>
    <h3>◆Data usage - purchase of additional plans</h3>
    <p>The service includes a data volume limit, selected domestic usage and usage period granted by the user's selection.</p>
    <p>The service is available with a data volume cap, selected domestic usage and usage period, which are assigned by the user's selection.</p>
    <p>The 24th hour after the start of the usage period is counted as one day.</p>
    <p>After selecting a data plan, regardless of whether or not you can start using the service, the service will start on the first day after the payment is completed. Please complete the procedure to start communication as soon as possible.</p>
    <p>After selecting a data plan, the amount of data and the number of days of usage cannot be changed, and if both selections are not completed, the next data plan cannot be selected.</p>
    <p>The 24th hour after the start of use is counted as a day.</p>
    <p>The packet communication service will be suspended when the data limit is exceeded or when the usage period has ended.</p>
    <p>If you wish to purchase an additional plan, you will need to apply for it via a dedicated webpage.</p>
    <p>Voice calls, SMS, MMS, TV calls and 64K digital data communication are not available.</p>
    <p>The transmission speed is the maximum value based on technical standards when sending and receiving, and does not indicate the actual transmission speed.</p>
    <p>Actual transmission speeds may vary depending on the transmission environment and network congestion.</p>
    <p>The actual communication speed may vary depending on the communication environment and network congestion.</p>
    <p>The service uses radio waves, so it may not be possible to use the service indoors or due to obstacles (buildings, terrain, etc.) in the vicinity, even within the service area. The service may also be unavailable in high-rise buildings, condominiums, etc., even in places with a good view.</p>
    <p>This service can be used overseas. Please check the dedicated website for details.</p>
    <p>The contract for this service is cancelled 365 days after the start of use of the service.</p>
    <p>The speed of communication may be restricted for customers who use a large amount of communication during a certain period of time. In addition, in order to provide better network quality, traffic information is collected, analysed and stored for each communication, and restrictions may be imposed on communications that are specified separately by the Company. </p>
    <p>The tethering option is available free of charge when using this service (depending on the type of device used, etc., this option may not be available).</p>
    <h3>◆Handling of the SIM card</h3>
    <p>Please note that incorrect handling may result in damage to the SIM card or handset.</p>
    <p>Do not touch the metal terminal part and always use the terminal in a clean condition.</p>
    <p>If you touch the metal terminal part, wipe it with a soft, dry cloth before use.</p>
    <p>When storing the product, do not expose it to direct sunlight or place it in a high temperature and humidity environment.</p>
    <p>Please make a separate note of the information you have registered on the SIM card yourself. In the unlikely event that the registered information is lost, we cannot be held responsible.</p>
    <h3>◆Safety precautions.</h3>
    <p>Wear gloves when removing the SIM card and ensure that the power is switched off.</p>
    <p>If the SIM card is used by a child, a parent or guardian should teach the child how to handle it. Also, make sure that the product is being used in accordance with the instructions.</p>
    <p>Do not place the SIM card in microwave ovens, other overheating cookers or high-pressure containers.</p>
    <p>Do not use or leave the SIM card in a hot place such as by a fire or stove. Doing so may cause melting, heat generation, smoking, flames, loss of data or malfunction.</p>
    <p>Keep out of reach of children. Doing so may cause accidents such as accidental ingestion or injury.</p>
    <h3>【Changes to the Important Information and the Terms and Conditions of Provision.】</h3>
    <p>The Company reserves the right to change any of the matters described in these Important Information and Terms and Conditions (hereinafter referred to as the "Terms and Conditions"). In such cases, the rates and other terms and conditions of provision shall be subject to the terms and conditions of the revised Statement of Terms and Conditions.</p>
    <p>In the event of any changes to the Terms and Conditions, prior notice will be given by posting on the Company's website, by sending text messages (i.e. messages using numbers, symbols and other characters sent from the subscriber's line or the Company's telecommunications equipment), or by any other method the Company deems appropriate.</p>
    <p>The latest Terms and Conditions will be posted on the Company's website.</p>
    <p>dedicated website：http://www.esimplus.jp/</p>
    `,
    transactions:`
    <h2>Indication regarding the Act on Specified Commercial Transactions</h2>
    <p>Distributor ：3rdcompass Inc.</p>
    <p>Representative ： Koutaro Kimura</p>
    <p>Location ：2F Shibuya 3-chome Square Bldg. 3-5-16 Shibuya, Shibuya-ku, Tokyo 150-0002, Japan</p>
    <p>Telephone number ：050-5369-1166</p>
    <p>Telephone receptionist ： 10:00 - 17:00 (except Saturdays, Sundays and national holidays)</p>
    <p>E-mail address: mob★3compass.net (Please change ★ to @ and reply)</p>
    <p>Website ：<a href="https://www.esimplus.jp/">https://www.esimplus.jp/</a></p>
    <p>Delivery date ：Date of receipt specified at the time of application</p>
    <p>Payment method ：Credit card payment is accepted.</p>
    `,
    newplan:`
      <h1>New European Plans Starting October 24, 2024! </br>* * Expanded Coverage ! * * </h1>
      <p>From October 24, 2024, we are excited to introduce our new European plans! Previously, we offered only the "European Tour" plan, but now, with country and region-specific options, they are more "cost-effective" and "user-friendly"! Improved usability will enhance your customer experience even further. If you would like to see a list of all product plans, please refer to the content below.</p>
    `
  }
}